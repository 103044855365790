import Vue from "vue";
import VueI18n from "vue-i18n";
import { messages } from "../Translations";

Vue.use(VueI18n);

function getLocale() {
  const urlParams = new URLSearchParams(window.location.href);
  const language = urlParams.get('language');

  if (language) {
    return language.substring(0, 2);
  }

  return navigator.language.substring(0, 2);
}

export const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: "en",
  messages,
});

export default i18n;
