
import Vue from "vue";
import Component from "vue-class-component";
import Loader from "./components/Loader.vue";
import StackTrace from "stacktrace-js";
import { MessageService } from "./MessageService";
import { Branch } from "./sinnoAppApi/Branch";
import { Customer } from "./sinnoAppApi/Customer";
import { Company } from "./sinnoAppApi/Company";
import { SinnoAppApi } from "./sinnoAppApi/SinnoAppApi";
import { WinIproApi } from "./winIproApi/WinIproApi";
import { QueryParamLoader } from "./winIproInterop/QueryParamLoader";
import { WinIproStateLoader } from "./winIproInterop/WinIproStateLoader";
import { TranslateResult } from "vue-i18n";

@Component({
    components: {
        Loader: Loader
    }
})
export default class App extends Vue {
    private winIproState: WinIproStateLoader =
        new QueryParamLoader(this.$route.query);

    public messageService: MessageService | null = null;
    public customer: Customer | null = null;
    public branch: Branch | null = null;
    public company: Company | null = null;

    public loading = true;

    private async mounted() {
        let fBranchId = null;
        let fCompanyId = null;

        try {
            const {
                companyMiddlewareId,
                customerId: customerWinIproId,
                authToken,
                branchId,
                middlewareEnvironment
                } = this.winIproState.load();

            fCompanyId = companyMiddlewareId
            fBranchId = branchId

            const sinnoApi = new SinnoAppApi(
                `https://sinnoapp.api${middlewareEnvironment == "staging" ? "-test" : ""}.ipro.de/v1`,
                authToken
            );

            const winIproApi = new WinIproApi(
                `https://winipro.api${middlewareEnvironment == "staging" ? "-test" : ""}.ipro.de/v1`,
                authToken
            );

            const company = await sinnoApi.companyByRealmId(
                `${middlewareEnvironment == "staging" ? "testing-" : ""}${companyMiddlewareId}`,
                'Android'
            );

            this.company = company[0];

            this.branch = {
                id: await winIproApi.branchMiddlewareId(companyMiddlewareId, branchId)
            }

            const customer =
                await winIproApi.customer(companyMiddlewareId, customerWinIproId);
            if(customer == null || !customer.sinnoAppAllowed) {
                this.loading = false;
                this.$router.push({name: "NotActivated"});
                return;
            }

            this.customer = {
                companyId: companyMiddlewareId,
                id: customer.id
            };

            const devices = await sinnoApi.devices(this.customer);

            if(devices.length == 0) {
                this.loading = false;
                this.$router.push({name: "Setup"})
                return;
            }

            this.messageService = new MessageService(sinnoApi, 5);

            this.loading = false;
            this.$router.push({name: "Chat"})

        } catch(error_) {
            const error = error_ as Error;
            this.loading = false
            let endUserMessage: TranslateResult;

            // winIPRO API
            if(error.name == "WinIproInteropError") {
                endUserMessage = this.$t("errors.communication_with_winipro");
            } else if(error.name == "MiddlewareContractViolation") {
                // No company return
                if (error.message == "[sinnoapp API -> companies] expected non empty response") {
                    endUserMessage = this.$t("errors.company_with_id_not_found", [fCompanyId]);
                }
                // No branch return
                else if (error.message == "[winIPRO API -> branches] expected non empty response") {
                    endUserMessage = this.$t("errors.branch_with_number_not_found", [fBranchId]);
                }
                // 401 branch request
                else if (error.message == "[winIPRO API -> branches] unexpected status 401") {
                    endUserMessage = this.$t("errors.access_timeout");
                }
                else endUserMessage = this.$t("errors.unknown_communication_error");
            } else {
                endUserMessage = this.$t("errors.unknown_error");
            }

            const trace = (await StackTrace.fromError(error)).reduce<string>(
                (acc, curr) => acc + `\n  at ${curr}`,
                ""
                )
            if(!(this.$route.name == "Error")) {
                 await this.$router.push({name: "Error", params: {
                endUserMessage: endUserMessage.toString(),
                technicalMessage: error.toString() + trace
            }})
            }
        }

    }
}
