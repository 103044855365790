export enum SupportedLanguageEnum {
  en = "en",
  de = "de",
  fr = "fr",
  nl = "nl"
}

export type MultilangTextType = {
  [key in SupportedLanguageEnum]: string;
};

export type ComponentTranslation = {
  [key: string]: MultilangTextType;
};

export const DeepLinksTranslations: ComponentTranslation = {
  "modules/appointment": {
    en: "appointment",
    de: "Terminvereinbarung",
    fr: "Rendez-vous",
    nl: "Afspraak"
  },
  "modules/passes/selection": {
    en: "passes",
    de: "Pässe",
    fr: "Passeports",
    nl: "Paspoorten"
  },
  "modules/passes/glasses": {
    en: "glasses passes",
    de: "Brillenpässe",
    fr: "Passe-lunettes",
    nl: "Bril gaat voorbij"
  },
  "modules/passes/lenses": {
    en: "lens passes",
    de: "Kontaktlinsenpässe",
    fr: "Porte-lentilles",
    nl: "Lenspassen"
  },
  "modules/passes/hearingaid": {
    en: "hearing aid passes",
    de: "Hörgerätepässe",
    fr: "Passe-prothèses auditives",
    nl: "Gehoorapparaat passen"
  },
  "modules/reminders": {
    en: "reminders",
    de: "Erinnerungen",
    fr: "Rappels",
    nl: "Herinneringen"
  },
  "modules/orderStatus": {
    en: "order status",
    de: "Auftragsstatus",
    fr: "Statut de la commande",
    nl: "Orderstatus"
  },
  "modules/webView/Documents": {
    en: "documents",
    de: "Dokumente",
    fr: "Documents",
    nl: "Documenten"
  }
};

export const messages = {
  en: {
    errors: {
      communication_with_winipro:
        "An error occurred in the communication with winIPRO!",
      company_with_id_not_found:
        "Company with ID {0} was not found in the cloud!",
      branch_with_number_not_found:
        "Your branch with number {0} was not found in the cloud!",
      access_timeout:
        "Permission to access the cloud has expired or is missing!",
      unknown_communication_error:
        "An error occurred in communication with the cloud!",
      unknown_error: "An unknown error has occurred!",
    },

    messages: {
      messages: "Messages",
      hideDetails: "Hide details",
      showDetails: "Show details",
      mark_message_as_read: "Mark customer messages as read.",
      not_activated_yet:
        "This customer has not yet been activated for the app. Please carry out the activation in winIPRO.",
      click_to_get_new_messages: "Click here to get new messages.",
      no_new_messages_loading:
        "No new messages are currently retrieved. Please close and reopen the winIPRO customer window.",
      execute_following_steps:
        "The customer has been successfully activated for the app. Please carry out the following steps:",
      setup: {
        header: "Set up app",
        install_the_app: "Install the app.",
        start_app_on_phone:
          "After installation, start the app on the smartphone.",
        input_mail_or_scan:
          "When searching for a company, enter the e-mail address of the branch or scan this QR code.",
        input_mail_and_password:
          "In the login window, enter the customer's email address and the temporary password that was sent to the customer via SMS.",
        after_new_password:
          "After the customer has set a new password for the app, he can use the app.",
      },
    },
  },

  de: {
    errors: {
      communication_with_winipro:
        "Ein Fehler in der Kommunikation mit winIPRO ist aufgetreten!",
      company_with_id_not_found:
        "Unternehmen mit der ID {0} wurde in der Cloud nicht gefunden!",
      branch_with_number_not_found:
        "Ihre Filiale mit der Nummer {0} wurde in der Cloud nicht gefunden!",
      access_timeout:
        "Die Zugangserlaubnis zur Cloud ist abgelaufen oder fehlt!",
      unknown_communication_error:
        "Ein Fehler in der Kommunikation mit der Cloud ist aufgetreten!",
      unknown_error: "Ein unbekannter Fehler ist aufgetreten!",
    },

    messages: {
      messages: "Nachrichten",
      hideDetails: "Details einblenden",
      showDetails: "Details ausblenden",
      mark_message_as_read: "Nachrichten des Kunden als gelesen markieren.",
      not_activated_yet:
        "Dieser Kunde wurde noch nicht für die App freigeschaltet. Bitte führen Sie die Freischaltung in winIPRO durch.",
      click_to_get_new_messages:
        "Klicken Sie hier um neue Nachrichten abzurufen.",
      no_new_messages_loading:
        "Es werden aktuell keine neuen Nachrichten mehr abgerufen. Bitte schließen und öffnen Sie die winIPRO Kundenkartei erneut.",
      execute_following_steps:
        "Der Kunde wurde erfolgreich für die App freigeschaltet. Bitte führen Sie nun folgende Schritte aus:",
      setup: {
        header: "App einrichten",
        install_the_app: "Installieren Sie die App.",
        start_app_on_phone:
          "Starten Sie nach der Installation die App auf dem Smartphone.",
        input_mail_or_scan:
          "Bei der Unternehmenssuche geben Sie die E-Mailadresse der Filiale ein oder scannen Sie diesen QR-Code ab.",
        input_mail_and_password:
          "Geben Sie im Anmeldefenster die E-Mailadresse des Kunden und das temporäre Kennwort, daß dem Kunden per SMS zugesendet wurde ein.",
        after_new_password:
          "Nachdem der Kunde ein neues Kennwort für die App festgelegt hat kann er diese verwenden.",
      },
    },
  },

  fr: {
    errors: {
      communication_with_winipro:
        "Une erreur s'est produite lors de la communication avec winIPRO!",
      company_with_id_not_found:
        "L'entreprise avec l'ID {0} n'a pas été trouvée dans le cloud!",
      branch_with_number_not_found:
        "Votre numéro de succursale {0} n'a pas été trouvé dans le cloud!",
      access_timeout:
        "L'autorisation d'accès au cloud a expiré ou est manquante!",
      unknown_communication_error:
        "Une erreur s'est produite dans la communication avec le cloud!",
      unknown_error: "Une erreur inconnue s'est produite!",
    },

    messages: {
      messages: "Messages",
      hideDetails: "Masquer les détails",
      showDetails: "Afficher les détails",
      mark_message_as_read: "Marquer les messages du client comme lus.",
      not_activated_yet:
        "Ce client n'a pas encore été activé pour l'application. Veuillez effectuer l'activation dans winIPRO.",
      click_to_get_new_messages:
        "Cliquez ici pour recevoir de nouvelles nouvelles.",
      no_new_messages_loading:
        "Aucun nouveau message n'est actuellement récupéré. Veuillez fermer et rouvrir le fichier client winIPRO.",
      execute_following_steps:
        "Le client a été activé avec succès pour l'application. Veuillez maintenant effectuer les étapes suivantes:",
      setup: {
        header: "Configurer l'application",
        install_the_app: "Installez l'application.",
        start_app_on_phone:
          "Après l'installation, démarrez l'application sur votre smartphone.",
        input_mail_or_scan:
          "Lors de la recherche d'une entreprise, saisissez l'adresse email de l'agence ou scannez ce code QR.",
        input_mail_and_password:
          "Dans la fenêtre de connexion, saisissez l'adresse e-mail du client et le mot de passe temporaire qui a été envoyé au client par SMS.",
        after_new_password:
          "Une fois que le client a défini un nouveau mot de passe pour l'application, il peut l'utiliser.",
      },
    },
  },

  nl: {
    errors: {
      communication_with_winipro:
        "Er is een fout opgetreden in de communicatie met winIPRO!",
      company_with_id_not_found:
        "Bedrijf met ID {0} is niet gevonden in de cloud!",
      branch_with_number_not_found:
        "Uw filiaalnummer {0} is niet gevonden in de cloud!",
      access_timeout:
        "De toegangsrechten voor de cloud zijn verlopen of ontbreken!",
      unknown_communication_error:
        "Er is een fout opgetreden in de communicatie met de cloud!",
      unknown_error: "Er is een onbekende fout opgetreden!",
    },

    messages: {
      messages: "Berichten",
      hideDetails: "Details verbergen",
      showDetails: "Details weergeven",
      mark_message_as_read: "Markeer de berichten van klanten als gelezen.",
      not_activated_yet:
        "Deze klant is nog niet geactiveerd voor de app. Voer de activering uit in winIPRO.",
      click_to_get_new_messages:
        "Klik hier voor nieuw nieuws.",
      no_new_messages_loading:
        "Er worden momenteel geen nieuwe berichten opgehaald. Sluit het winIPRO-klantenbestand en open het opnieuw.",
      execute_following_steps:
        "De klant is succesvol geactiveerd voor de app. Voer nu de volgende stappen uit:",
      setup: {
        header: "App instellen",
        install_the_app: "Installeer de app.",
        start_app_on_phone:
          "Na installatie start u de app op uw smartphone.",
        input_mail_or_scan:
          "Bij het zoeken naar een bedrijf vult u het e-mailadres van het filiaal in of scant u deze QR-code.",
        input_mail_and_password:
          "Voer in het inlogvenster het e-mailadres van de klant in en het tijdelijke wachtwoord dat via sms naar de klant is verzonden.",
        after_new_password:
          "Nadat de klant een nieuw wachtwoord voor de app heeft ingesteld, kan hij deze gebruiken.",
      },
    },
  },
};
