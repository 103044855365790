import { Branch } from "./sinnoAppApi/Branch";
import { Customer } from "./sinnoAppApi/Customer";
import { Message } from "./sinnoAppApi/Message";
import { SinnoAppApi } from "./sinnoAppApi/SinnoAppApi";

export class MessageService {
  public constructor(client: SinnoAppApi, pollIntervalSeconds: number) {
    this.client = client;
    this.pollIntervalSeconds = pollIntervalSeconds;
    this.maxPolling = Math.round((60 * 10) / pollIntervalSeconds); // 10 Minutes
  }

  public async *newestMessages(
    customer: Customer,
    throwError = false
  ): AsyncGenerator<Message[], Message[]> {
    let lastMessage: Message | null = null;
    let currentPollingCount = 0;

    while (currentPollingCount < this.maxPolling) {
      currentPollingCount++;

      const messageIterator = this.messagesUpTo(lastMessage, customer);
      for await (const messages of messageIterator) {
        yield messages;
        lastMessage = messages[messages.length - 1] ?? lastMessage;
      }
      await this.sleep(this.pollIntervalSeconds);
    }

    if (throwError) throw new Error("End Polling");
    else return [];
  }

  public async *messagesBefore(
    exclusiveStart: Message,
    customer: Customer
  ): AsyncGenerator<Message[], Message[]> {
    let found = -1;
    let page = 0;
    let messages: Message[] = [];

    while (found == -1) {
      messages = await this.client.messages(customer, {
        pageNumber: page,
        pageSize: SinnoAppApi.maxPageSize,
      });
      found = messages.findIndex((m) => m.id == exclusiveStart.id);
      ++page;
    }
    if (found != messages.length - 1) {
      yield messages.slice(found + 1).reverse();
    }

    let pageSize = messages.length;
    while (pageSize > 0) {
      const messages = await this.client.messages(customer, {
        pageNumber: page,
        pageSize: SinnoAppApi.maxPageSize,
      });
      yield messages.slice().reverse();
      pageSize = messages.length;
      ++page;
    }
    return [];
  }

  public sendMessage(
    message: string,
    sender: Branch,
    recipient: Customer
  ): Promise<void> {
    return this.client.sendMessage(
      {
        contactTime: new Date(),
        content: message,
      },
      recipient,
      sender
    );
  }

  public setMessagesAsRead(recipient: Customer): Promise<void> {
    return this.client.setMessagesAsRead(recipient);
  }

  private async *messagesUpTo(
    lastMessage: Message | null, // null -> return the first page
    customer: Customer
  ): AsyncGenerator<Message[], void, unknown> {
    for (let lastMessageIndex = -1, page = 0; lastMessageIndex == -1; ++page) {
      const messages = await this.client.messages(customer, {
        pageNumber: 0,
        pageSize: SinnoAppApi.maxPageSize,
      });
      lastMessageIndex = messages.findIndex((m) => m.id === lastMessage?.id);
      const sliceTo = lastMessageIndex == -1 ? undefined : lastMessageIndex;
      yield messages.slice(0, sliceTo).reverse();

      if (lastMessage == null) {
        lastMessageIndex = SinnoAppApi.maxPageSize;
      }
    }
  }

  private sleep(seconds: number) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }

  private readonly client: SinnoAppApi;
  private readonly pollIntervalSeconds: number;
  private readonly maxPolling: number;
}
