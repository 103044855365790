import { MiddlewareContractViolation } from "@/MiddlewareContractViolation";
import { authHeader } from "@/OAuth";
import { Customer } from "./Customer";

export class WinIproApi {
  public constructor(baseUrl: string, accessToken: string) {
    this.baseUrl = baseUrl;
    this.accessToken = accessToken;
  }

  public async customer(
    companyId: string,
    customerId: string
  ): Promise<Customer | null> {
    const url = new URL(`${this.baseUrl}/Companies/${companyId}/Customers`);
    url.searchParams.set("CustomerIdIPRO", customerId);

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: "GET",
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        "winIPRO",
        "customers"
      );
    }

    const json = (await response.json()) as CustomerResponse;

    if (json.Customers.length < 1) {
      return null;
    }
    const customer = json.Customers[0];
    return {
      id: customer.CustomerId.toString(),
      sinnoAppAllowed: customer.UsesApp,
    };
  }

  public async branchMiddlewareId(
    companyId: string,
    branchId: string
  ): Promise<string> {
    const url = new URL(`${this.baseUrl}/Companies/${companyId}/Branches`);
    url.searchParams.set("BranchIdIPRO", branchId);

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: "GET",
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        "winIPRO",
        "branches"
      );
    }

    const json = (await response.json()) as BranchResponse;

    if (json.Branches.length != 1) {
      throw new MiddlewareContractViolation(
        "expected non empty response",
        "winIPRO",
        "branches"
      );
    }

    return json.Branches[0].BranchId.toString();
  }

  private readonly baseUrl: string;
  private readonly accessToken: string;

  private get authHeader() {
    return authHeader(this.accessToken);
  }
}

interface CustomerResponse {
  Customers: {
    CustomerId: number;
    UsesApp: boolean;
  }[];
}

interface BranchResponse {
  Branches: { BranchId: number }[];
}
