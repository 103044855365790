import { CreateMessageRequest } from "./CreateMessageRequest";
import { MessageParty } from "./MessageParty";
import { Message } from "./Message";
import { MessageStatus } from "./MessageStatus";
import { Customer } from "./Customer";
import { Branch } from "./Branch";
import { PaginationOptions } from "./PaginationOptions";
import { authHeader } from "@/OAuth";
import { Device } from "./Device";
import { Company } from "./Company";
import { OsType } from "./OsType";
import { MiddlewareContractViolation } from "@/MiddlewareContractViolation";

export class SinnoAppApi {
  public static maxPageSize = 25;

  public async setMessagesAsRead(recipient: Customer): Promise<void> {
    const json: MessagesSetRead = {
      ForCreaterApp: "user",
    };

    const response = await fetch(
      `${this.customerUrl(recipient)}/Messages/SetRead`,
      {
        headers: {
          ...this.authHeader,
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify(json),
      }
    );

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        "sinnoapp",
        "messages"
      );
    }
  }

  public async sendMessage(
    message: CreateMessageRequest,
    recipient: Customer,
    sender: Branch
  ): Promise<void> {
    const json: MessagePostJson = {
      BranchId: Number.parseInt(sender.id),
      ContactTime: message.contactTime.toISOString(),
      CreaterApp: "company",
      MessageContent: message.content,
      Title: message.title,
    };

    const response = await fetch(`${this.customerUrl(recipient)}/Messages`, {
      headers: {
        ...this.authHeader,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(json),
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        "sinnoapp",
        "messages"
      );
    }
  }
  /**
   * **postcondition** messages are ordered in descending order: ie. newest first
   */
  public async messages(
    customer: Customer,
    pagination: PaginationOptions
  ): Promise<Message[]> {
    const url = new URL(`${this.customerUrl(customer)}/Messages`);
    url.searchParams.set("Limit", pagination.pageSize.toString());
    url.searchParams.set(
      "Offset",
      (pagination.pageNumber * pagination.pageSize).toString()
    );

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: "GET",
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        "sinnoapp",
        "messages"
      );
    }

    const json = (await response.json()) as MessagesGetJson;
    return json.Messages.map((message) => ({
      branchId: message.BranchId.toString(),
      contactTime: new Date(message.ContactTime),
      content: message.MessageContent ?? "",
      sender: message.CreaterApp,
      id: message.ContactId.toString(),
      status: message.ContactStatus,
      title: message.Title,
      showDate: false,
    }));
  }

  public async devices(customer: Customer): Promise<Device[]> {
    const response = await fetch(`${this.customerUrl(customer)}/Devices`, {
      headers: this.authHeader,
      method: "GET",
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        "sinnoapp",
        "devices"
      );
    }

    return (await response.json()).Devices;
  }

  public async companyByRealmId(
    realmId: string,
    os: OsType
  ): Promise<Company[]> {
    const response = await fetch(
      `${this.baseUrl}/Companies?RealmId=${realmId}`,
      {
        headers: {
          "X-IPROSINNO-OS": os,
        },
        method: "GET",
      }
    );

    if (!response.ok) {
      alert(response.statusText);
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        "sinnoapp",
        "companies"
      );
    }

    const json = await response.json();

    if (json.Companies.length < 1) {
      throw new MiddlewareContractViolation(
        "expected non empty response",
        "sinnoapp",
        "companies"
      );
    }

    return json.Companies;
  }

  public constructor(baseUrl: string, accessToken: string) {
    this.baseUrl = baseUrl;
    this.accessToken = accessToken;
  }

  private readonly baseUrl: string;
  private readonly accessToken;

  private customerUrl(customer: Customer): string {
    return `${this.baseUrl}/Companies/${customer.companyId}/Customers/${customer.id}`;
  }

  private get authHeader() {
    return authHeader(this.accessToken);
  }
}

interface MessagesGetJson {
  Messages: {
    ContactId: number;
    ReferenceOrderId: number;
    BranchId: number;
    ContactTime: number;
    Title: string;
    MessageContent: string | null;
    EmployeeId: number;
    ContactStatus: MessageStatus;
    CreaterApp: MessageParty;
    LastUpdate: string;
  }[];
}

interface MessagePostJson {
  ReferenceOrderId?: number;
  BranchId: number;
  EmployeeId?: number;
  ContactTime: string;
  Title?: string;
  MessageContent: string | null;
  CreaterApp: MessageParty;
}

interface MessagesSetRead {
  ForCreaterApp: MessageParty;
}
