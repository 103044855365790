import { Dictionary } from "vue-router/types/router";
import { WinIproInteropError } from "./WinIproInteropError";
import { WinIproState } from "./WinIproState";
import { WinIproStateLoader } from "./WinIproStateLoader";

export class QueryParamLoader implements WinIproStateLoader {
    public constructor(queryParams: Dictionary<string | (string | null)[]>) {
        this.params = queryParams;
    }

    public load(): WinIproState {
        const version = this.valueOf("version");
        if (version != "1") {
            throw new WinIproInteropError("Bad interop api version");
        }
        const env = this.valueOfOr("middleware-environment", "production");
        if (env != "staging" && env != "production") {
            throw new WinIproInteropError(`Bad param "middleware-environment"`);
        }

        return {
            version: version,
            authToken: this.valueOf("auth-token"),
            branchId: this.valueOf("branch-id"),
            companyMiddlewareId: this.valueOf("company-middleware-id"),
            customerId: this.valueOf("customer-id"),
            middlewareEnvironment: env
        }
    }

    private valueOf(param: string): string {
        const value = this.params[param]
        if (typeof value != "string") {
            throw new WinIproInteropError(`Bad param "${param}"`);
        }

        return value;
    }

    private valueOfOr(param: string, defaultVal: string): string {
        const value = this.params[param]
        if (value == null) {
            return defaultVal;
        } else if (typeof value != "string") {
            throw new WinIproInteropError(`Bad param "${param}"`);
        } else {
            return value
        }
    }

    private readonly params: Dictionary<string | (string | null)[]>;
}
