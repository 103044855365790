import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/chat",
    name: "Chat",
    component: () => import('../views/Chat.vue')
  },
  {
    path: "/setup",
    name: "Setup",
    component: () => import('../views/InstallInstructions.vue')
  },
  {
    path: "/not-activated",
    name: "NotActivated",
    component: () => import("../views/CustomerNotActivated.vue")
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/ErrorView.vue"),
    props: true
  }
]

const router = new VueRouter({
  routes
})

export default router
